@import '@socialbrothers/assets/styles/abstracts';

// .ResetPasswordForm {
//   .Footer {
//     text-align: center;
//     padding-top: px(2);
//     margin-top: px(3.25);
//     border-top: 1px solid color(gray, 200);

//     a {
//       display: block;
//       color: color(primary);
//     }
//   }
// }

.ResetPasswordForm {
	.Footer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		margin-top: px(2);
	}

	.FooterLinks {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}