.Toastify {
	&__toast-container {
		padding: 0 !important;

		@media only screen and (max-width: 480px) {
			padding: 15px !important;
		}
	}

	&__toast {
		display: flex;
		align-items: flex-start;
		margin-bottom: px(1) !important;
		border-radius: $border-radius !important;
		box-shadow: $box-shadow !important;

		&-body {
			display: flex;
			align-items: center;
			font-family: $font-family-base;
			line-height: $line-height-base;

			&::before {
				content: '\f06a';

				display: block;
				margin-right: px(1);

				transform: translateY(-1px);

				font-family: $font-family-icons;
			}
		}

		&--success .Toastify__toast-body::before {
			content: '\f058';
		}

		&--error .Toastify__toast-body::before {
			content: '\f057';
		}

		&--warning .Toastify__toast-body::before {
			content: '\f06a';
		}

		&--info .Toastify__toast-body::before {
			content: '\f05a';
		}
	}
}

$rt-toast-width: 280px;
$rt-toast-background: #fff;
$rt-toast-min-height: 48px;
$rt-toast-max-height: 800px;

$rt-color-default: color(white);
$rt-color-dark: color(black);

$rt-color-info: color(info);
$rt-color-success: color(success);
$rt-color-warning: color(warning);
$rt-color-error: color(danger);

@import '~react-toastify/scss/main.scss';
