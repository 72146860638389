@import '@socialbrothers/assets/styles/abstracts';

.Repeater {
	margin-bottom: px(3);

	.Fieldset {
		margin: 0;
		padding: 0;
	}

	.Title {
		display: inline-block;
		margin-left: -5px;
		background-color: color(white);
		font-weight: $font-weight-bold;
	}

	.Fields {
		padding: px(1.75) 0;
	}

	.Field {
		margin-bottom: px(2);
		padding: 0 px(2) px(3) px(2);
		border-bottom: solid color(gray, 200) 1px;

		> *:last-child {
			margin-bottom: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
			border: none;
		}

		&__Header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: px(1);
		}

		&__Title {
			margin-bottom: px(1);
			font-size: $font-size-sm;
			font-weight: $font-weight-bold;
		}

		&__Delete {
			color: color(danger);
			cursor: pointer;
		}
	}

	&--Error {
		.Fieldset,
		.Field {
			border-color: color(danger);
		}

		legend {
			color: color(danger);
		}
	}

	&__Error,
	&__Description {
		margin-top: px(0.5);
		padding-left: px(2);
		font-size: $font-size-xs;
	}

	&__Error {
		color: color(danger);
	}
}
