@function color($color-name: primary, $color-variant: default) {
	@return map-get(map-get($theme-colors, $color-name), $color-variant);
}

@function spacing($spacing-name: md) {
	@return map-get($spacings, $spacing-name);
}

@function timing($timing-name: md) {
	@return map-get($timings, $timing-name);
}

@function px($value: 1) {
	@return $value * 8px;
}
