@import '@socialbrothers/assets/styles/abstracts';

.ForgotPasswordForm {
	.Footer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		margin-top: px(2);
	}

	.FooterLinks {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
