fieldset {
	border: 1px solid $input-border-color;
	border-radius: $input-radius;

	font-size: $input-label-font-size;

	legend {
		position: relative;
		z-index: 1;
		margin: 0 0 -6px px(1);
		padding: 0 px(1);
		font-size: $font-size-sm;
		line-height: 1;
	}
}

label {
	display: block;
	padding-bottom: 5px;

	color: $body-color;

	cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	margin: 0;

	-webkit-appearance: none;
}

input[type='text'],
input[type='file'],
input[type='email'],
input[type='number'],
input[type='password'],
select,
textarea {
	display: block;
	width: 100%;
	padding: px(1.75) px(2);

	border: 1px solid $input-border-color;
	border-radius: $input-radius;

	background: $input-background-color;

	color: $body-color;
	font-family: $font-family-base;

	font-size: $input-font-size;

	appearance: none;

	&::placeholder {
		opacity: 0.35;

		color: $input-placeholder-color;
		font-weight: $font-weight-regular;
	}

	&:focus {
		border-color: $input-active-color;
		outline: none;
	}

	&:disabled {
		color: color(gray, 400);
	}
}

input[type='checkbox'],
input[type='radio'] {
	display: none;

	& + label {
		position: relative;
		padding-bottom: 0;
		padding-left: 25px;
		font-size: $font-size-sm;

		user-select: none;

		&::before {
			content: '';

			position: absolute;
			top: 50%;
			left: 0px;

			width: 16px;
			height: 16px;
			transform: translateY(-50%);

			border: 1px solid $input-border-color;
			border-radius: 4px;

			background: $input-background-color;
		}

		&::after {
			content: '\f00c';
			position: absolute;
			top: 50%;
			left: 0px;

			width: 17px;
			height: 16px;
			transform: translateY(-50%);

			opacity: 0;

			color: color(primary);

			font-family: $font-family-icons;
			font-size: 9px;
			line-height: 16px;
			text-align: center;
		}
	}

	&:checked + label {
		&::after {
			opacity: 1;
		}
	}

	&:checked + label {
		&::before {
			border-color: color(primary);
		}
	}
}

input[type='radio'] {
	& + label {
		position: relative;

		padding-bottom: 0;
		padding-left: 25px;

		&::before {
			border-radius: 100%;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 5px;
			width: 6px;
			height: 6px;
			transform: translateY(-50%);
			border-radius: 100%;
			border-radius: 100%;
			background-color: color(primary);
		}
	}
}

select {
	position: relative;

	padding-right: 30px;

	&::after {
		content: '\f078';

		position: absolute;
		top: 50%;
		right: 20px;

		transform: translate(0, -50%);

		font-family: 'Font awesome 5 Pro';

		font-size: 16px;

		pointer-events: none;
	}

	select:hover {
		cursor: pointer;
	}

	select:disabled {
		opacity: 0.5;
	}
}

textarea {
	display: block;
	width: 100%;
	min-height: 100px;

	line-height: 1.4;

	appearance: none;

	&::placeholder {
		color: $input-placeholder-color;
	}

	&:focus {
		border-color: $input-active-color;
		outline: none;
	}
}
