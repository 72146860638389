@import '@socialbrothers/assets/styles/abstracts';

.SelectInput {
  position: relative;
  width: 100%;
  font-size: $font-size-sm;

  .Icon {
    position: absolute;
    top: 50%;
    right: px(2);
    transform: translateY(-50%);
    pointer-events: none;
  }

  &__Select {
    width: 100%;
    height: 100%;

    > div:first-of-type {
      z-index: 1;
      box-shadow:  none !important;
      border: none !important;
      background: transparent !important;
    }

    > div:nth-of-type(2) {
      z-index: 2;
    }
  }
}
